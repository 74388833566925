export const rules = {
    required: v =>
        !!v ||
        `
  Ce champ est obligatoire`,
    email: v => /.+@.+\..+/.test(v) || "L'email doit être valide",
    max(number, message = undefined) {
        if (!message) {
            message = `Ce champs doit être inferieure ou égale à ${number}`
        }
        return v => (v && v <= number) || message
    },
    min(number) {
        return v => (v && v >= number) || `Ce champs doit être supérieure ou égale à ${number}`
    },
    diff(array, name = "Ce nom") {
        for (let item of array) {
            if (typeof item != "string") {
                item = JSON.stringify(item)

                alert(item)
            }
        }
        return v => (v && !array.includes(v)) || `${name} n'est pas disponible`
    },
};

/**
 * @Function
 */

export const functions = {
    getBoolsData() {
        return [{
                name: "Oui",
                value: true
            },
            {
                name: "Non",
                value: false
            },
        ]
    },
    getTimesTableTypes() {
        return [{
                name: "Cours",
                value: "course"
            },
            {
                name: "Partiel",
                value: "exam"
            },
        ]
    },
    getIdList(array) {
        let result = [];
        array.map(item => {
            result.push(item._id);
        });
        return result;
    },
    getNameList(array, field = "name", unWantedList = []) {
        let result = [];
        array.map(item => {
            /**
             * Verification lang
             */
            if (item.fr && item.fr[field]) {
                result.push(item.fr[field]);
            } else if (item.en && item.en[field]) {
                result.push(item.en[field]);
            } else if (item && item[field]) {
                if (!unWantedList.includes(item[field])) {
                    result.push(item[field]);
                }
            }
        });
        return result;
    },

    getItemId(array, item, field = "name", value = "_id") {
        for (let i = 0; i < array.length; i++) {
            const element = array[i];

            if (element.fr && element.fr[field]) {
                if (element.fr[field] == item) return element[value];
            } else if (element.en && element.en[field]) {
                if (element.en[field] == item) return element[value];
            } else if (element && element[field]) {
                if (element[field] == item) {

                    return element[value]
                }
            }
        }
        return undefined;
    },
    getItemFromList(array, item, field = "_id", ) {
        for (let i = 0; i < array.length; i++) {
            const element = array[i];
            if (element[field] == item) return element;
        }
        return undefined;
    },
    getDate(date) {
        date = new Date(date);

        function getDoubleNumber(number) {
            return Number(number) >= 10 ? number : `0${number}`;
        }
        return `${getDoubleNumber(date.getDate())}/${getDoubleNumber(
      date.getMonth()
    )}/${date.getFullYear()} `;
    },
    getDateTime(date) {
        date = new Date(date);

        function getDoubleNumber(number) {
            return Number(number) >= 10 ? number : `0${number}`;
        }
        return `${getDoubleNumber(date.getDate())}/${getDoubleNumber(
      date.getMonth() + 1
    )}/${date.getFullYear()} at ${getDoubleNumber(
      date.getHours()
    )}:${getDoubleNumber(date.getMinutes())}`;
    },
    getDoubleNumber(number) {
        return Number(number) >= 10 ? number : `0${number}`;
    },

    getStringFromArray(array) {
        if (Array.isArray(array)) {
            return array.join(",");
        }
    },
    limitTextCharacters(text, limit = 200) {
        if (text.length > limit) return text.slice(0, limit - 3) + "...";
        return text;
    },
    downloadFromLink(url) {
        return new Promise((resolve, reject) => {
            try {
                // const axios = require("axios")
                // axios.get(url).then(() => resolve(true))
                const link = document.createElement('a')
                link.href = url
                link.target = "_BLANK"
                    // link.download = "label.pdf"
                link.href = url
                link.click()
                URL.revokeObjectURL(link.href)
                resolve(true)
            } catch (err) {
                reject(err)
            }
        })
    },

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    },
    getRequestTypes(asJson = true) {
        const emptyCashRegister = "Vider la caisse"
        const depositInBankAccount = "Dépôt sur compte bancaire"
        const entry = "Entrer"

        return asJson ? { emptyCashRegister, entry, depositInBankAccount } : [emptyCashRegister, depositInBankAccount, entry]
    },

    getUserRolesWithoutAdmin(asJson = false) {
        const cashRegister = "Caisse"
        const managerBVU = "Manager BVU"
        const homeBVU = "Acceuil BVU"
        const financialManager = "Resp.Fin."

        return asJson ? { cashRegister, financialManager, managerBVU, homeBVU } : [cashRegister, financialManager, managerBVU, homeBVU]
    },
    getUserRoles(asJson = true) {
        const cashRegister = "Caisse"
        const managerBVU = "Manager BVU"
        const homeBVU = "Acceuil BVU"
        const financialManager = "Resp.Fin."
        const admin = "Administrateur"

        return asJson ? { cashRegister, financialManager, managerBVU, homeBVU, admin } : [cashRegister, financialManager, managerBVU, homeBVU, admin]
    },
    formatNumber(number, minimumFractionDigits = 0) {
        if (!number || !Number(number)) return number

        // return number
        return (number).toLocaleString(
            undefined, // leave undefined to use the visitor's browser 
            // "en-US", // leave undefined to use the visitor's browser 
            // 'de-DE', // leave undefined to use the visitor's browser 
            { minimumFractionDigits }
        );
    },
    getCountries() {
        return [
            "Afghanistan",
            "Afrique du Sud",
            "Albanie",
            "Algérie",
            "Allemagne",
            "Andorre",
            "Angola",
            "Anguilla",
            "Antarctique",
            "Antigua-et-Barbuda",
            "Antilles néerlandaises",
            "Arabie saoudite",
            "Argentine",
            "Arménie",
            "Aruba",
            "Australie",
            "Autriche",
            "Azerbaïdjan",
            "Bahamas",
            "Bahreïn",
            "Bangladesh",
            "Barbade",
            "Bélarus",
            "Belgique",
            "Belize",
            "Bénin",
            "Bermudes",
            "Bhoutan",
            "Bolivie",
            "Bosnie-Herzégovine",
            "Botswana",
            "Brésil",
            "Brunéi Darussalam",
            "Bulgarie",
            "Burkina Faso",
            "Burundi",
            "Cambodge",
            "Cameroun",
            "Canada",
            "Cap-Vert",
            "Ceuta et Melilla",
            "Chili",
            "Chine",
            "Chypre",
            "Colombie",
            "Comores",
            "Congo-Brazzaville",
            "Corée du Nord",
            "Corée du Sud",
            "Costa Rica",
            "Côte d’Ivoire",
            "Croatie",
            "Cuba",
            "Danemark",
            "Diego Garcia",
            "Djibouti",
            "Dominique",
            "Égypte",
            "El Salvador",
            "Émirats arabes unis",
            "Équateur",
            "Érythrée",
            "Espagne",
            "Estonie",
            "État de la Cité du Vatican",
            "États fédérés de Micronésie",
            "États-Unis",
            "Éthiopie",
            "Fidji",
            "Finlande",
            "France",
            "Gabon",
            "Gambie",
            "Géorgie",
            "Géorgie du Sud et les îles Sandwich du Sud",
            "Ghana",
            "Gibraltar",
            "Grèce",
            "Grenade",
            "Groenland",
            "Guadeloupe",
            "Guam",
            "Guatemala",
            "Guernesey",
            "Guinée",
            "Guinée équatoriale",
            "Guinée-Bissau",
            "Guyana",
            "Guyane française",
            "Haïti",
            "Honduras",
            "Hongrie",
            "Île Bouvet",
            "Île Christmas",
            "Île Clipperton",
            "Île de l'Ascension",
            "Île de Man",
            "Île Norfolk",
            "Îles Åland",
            "Îles Caïmans",
            "Îles Canaries",
            "Îles Cocos - Keeling",
            "Îles Cook",
            "Îles Féroé",
            "Îles Heard et MacDonald",
            "Îles Malouines",
            "Îles Mariannes du Nord",
            "Îles Marshall",
            "Îles Mineures Éloignées des États-Unis",
            "Îles Salomon",
            "Îles Turks et Caïques",
            "Îles Vierges britanniques",
            "Îles Vierges des États-Unis",
            "Inde",
            "Indonésie",
            "Irak",
            "Iran",
            "Irlande",
            "Islande",
            "Israël",
            "Italie",
            "Jamaïque",
            "Japon",
            "Jersey",
            "Jordanie",
            "Kazakhstan",
            "Kenya",
            "Kirghizistan",
            "Kiribati",
            "Koweït",
            "Laos",
            "Lesotho",
            "Lettonie",
            "Liban",
            "Libéria",
            "Libye",
            "Liechtenstein",
            "Lituanie",
            "Luxembourg",
            "Macédoine",
            "Madagascar",
            "Malaisie",
            "Malawi",
            "Maldives",
            "Mali",
            "Malte",
            "Maroc",
            "Martinique",
            "Maurice",
            "Mauritanie",
            "Mayotte",
            "Mexique",
            "Moldavie",
            "Monaco",
            "Mongolie",
            "Monténégro",
            "Montserrat",
            "Mozambique",
            "Myanmar",
            "Namibie",
            "Nauru",
            "Népal",
            "Nicaragua",
            "Niger",
            "Nigéria",
            "Niue",
            "Norvège",
            "Nouvelle-Calédonie",
            "Nouvelle-Zélande",
            "Oman",
            "Ouganda",
            "Ouzbékistan",
            "Pakistan",
            "Palaos",
            "Panama",
            "Papouasie-Nouvelle-Guinée",
            "Paraguay",
            "Pays-Bas",
            "Pérou",
            "Philippines",
            "Pitcairn",
            "Pologne",
            "Polynésie française",
            "Porto Rico",
            "Portugal",
            "Qatar",
            "R.A.S. chinoise de Hong Kong",
            "R.A.S. chinoise de Macao",
            "régions éloignées de l’Océanie",
            "République centrafricaine",
            "République démocratique du Congo",
            "République dominicaine",
            "République tchèque",
            "Réunion",
            "Roumanie",
            "Royaume-Uni",
            "Russie",
            "Rwanda",
            "Sahara occidental",
            "Saint-Barthélémy",
            "Saint-Kitts-et-Nevis",
            "Saint-Marin",
            "Saint-Martin",
            "Saint-Pierre-et-Miquelon",
            "Saint-Vincent-et-les Grenadines",
            "Sainte-Hélène",
            "Sainte-Lucie",
            "Samoa",
            "Samoa américaines",
            "Sao Tomé-et-Principe",
            "Sénégal",
            "Serbie",
            "Serbie-et-Monténégro",
            "Seychelles",
            "Sierra Leone",
            "Singapour",
            "Slovaquie",
            "Slovénie",
            "Somalie",
            "Soudan",
            "Sri Lanka",
            "Suède",
            "Suisse",
            "Suriname",
            "Svalbard et Île Jan Mayen",
            "Swaziland",
            "Syrie",
            "Tadjikistan",
            "Taïwan",
            "Tanzanie",
            "Tchad",
            "Terres australes françaises",
            "Territoire britannique de l'océan Indien",
            "Territoire palestinien",
            "Thaïlande",
            "Timor oriental",
            "Togo",
            "Tokelau",
            "Tonga",
            "Trinité-et-Tobago",
            "Tristan da Cunha",
            "Tunisie",
            "Turkménistan",
            "Turquie",
            "Tuvalu",
            "Ukraine",
            "Union européenne",
            "Uruguay",
            "Vanuatu",
            "Venezuela",
            "Viêt Nam",
            "Wallis-et-Futuna",
            "Yémen",
            "Zambie",
            "Zimbabwe"
        ]
    }

};