<template>
  <v-card class="px-3" color="white">
    <div class="container">
      <div  class="rounded-pill  text-center primary py-4 px-5" dark>
          <p class="py-0 my-0 text-h5 white--text">Voulez vous vraiment supprimer {{collection}} ?</p>
      </div>
      <!-- {{ fields }} -->

      <v-card-text>
        <div class="text-h6 pa-5">Tout les collections ayant une relation avec cette collection seront aussi supprimer</div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          :loading="loading"
          @click="submit()"
          color="main"
          class="white--text primary"
          >Supprimer</v-btn
        >
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
// import { functions } from "../../utils/constant.js";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    collection: {
      type: String,
      default: "cette collection",
    },

    callback: {
      required: true,
    },
  },
  methods: {
    async submit() {
        this.loading = true;
        let data = {};
        data.id = this.id ?? undefined;
        if (data.id == undefined) {
          this.$store.dispatch("setSnackMessage", {
            message: "Quelque chose s'est mal passé",
            snackbar: {
              bg: "red",
              color: "white",
              status: true,
            },
          });
        }
        try {
          const response = await this.callback(data);
          this.$emit("success");
          console.log(response);
          this.$store.dispatch("setSnackMessage", {
            message:
              this.collection +
              ` a été supprimé avec success`,
            snackbar: {
              color: "red",
              status: true,
            },
          });
        } catch (error) {
          this.$store.dispatch("setSnackMessage", {
            message: "Something went wrong",
            snackbar: {
              bg: "red",
              color: "white",
              status: true,
            },
          });
        }

        this.loading = false;
    },
  },
  mounted() {},
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style></style>
