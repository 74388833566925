<template>
  <v-card class="px-3" color="white">
    <div class="container">
      <v-card-title>{{ title }}</v-card-title>
      <!-- {{ fields }} -->
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col
            v-for="(field, i) of fields"
            :key="i"
            :cols="field.col"
            :md="field.md ? field.md : field.col"
            v-show="isValidField(field) "

          >
            <div v-if="isValidField(field)">
              <div v-if="getCondition(field.condition)">
                <!-- Check if bool showing condition -->
                <div v-if="field.type == 'String'">
                  <div v-if="field.boolShowingCondition">
                    <h3 class="mb-0 pb-0">
                      {{ field.boolShowingCondition.label }}
                    </h3>
                    <v-radio-group
                      row
                      v-model="model[field.name + 'BoolCondition']"
                    >
                      <v-radio
                        color="primary"
                        :label="field.boolShowingCondition.trueText"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        color="green"
                        :label="field.boolShowingCondition.falseText"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                    <div v-if="model[field.name + 'BoolCondition'] == true">
                      <v-text-field
                        v-model="model[field.name]"
                        :rules="getFieldRules(field)"
                        color="main"
                        dense
                        :label="field.label"
                        :prepend-icon="field.icon ? field.icon : 'mdi-text'"
                        outlined
                      />
                    </div>
                  </div>
                  <v-text-field
                    v-else
                    v-model="model[field.name]"
                    :rules="getFieldRules(field)"
                    color="main"
                    dense
                    :label="field.label"
                    :prepend-icon="field.icon ? field.icon : 'mdi-text'"
                    outlined
                  >
                  </v-text-field>
                </div>
              </div>

              <v-text-field
                v-if="field.type == 'Number'"
                type="number"
                v-model="model[field.name]"
                :rules="getFieldRules(field)"
                dense
                color="main"
                :label="field.label"
                :prepend-icon="field.icon ? field.icon : 'mdi-numeric'"
                outlined
              >
              </v-text-field>

              <div v-if="field.type == 'Select'">
                <v-select
                  :items="field.select.data"
                  :label="field.label"
                  v-model="model[field.name]"
                  :rules="getFieldRules(field)"
                  outlined
                  dense
                  :prepend-icon="field.icon ? field.icon : 'mdi-select'"
                  chips
                  :multiple="field.select.multiple"
                ></v-select>

                <v-text-field
                  v-if="
                    field.select.allowOtherValue == true &&
                    model[field.name] == 'Autre'
                  "
                  v-model="model[field.name + 'Other']"
                  :rules="getFieldOtherRules(field)"
                  color="main"
                  :label="'Spécifier votre suggestion'"
                  :prepend-icon="field.icon ? field.icon : 'mdi-text'"
                  outlined
                />
              </div>

              <div v-if="field.type == 'Color'">
                <v-color-picker
                  v-model="model[field.name]"
                  class="ma-2"
                  hide-canvas
                ></v-color-picker>
              </div>
              
              <div v-if="field.type == 'Date'">
                <v-menu
                  ref="dateMenu"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="model[field.name]"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-combobox
                      v-model="model[field.name]"
                      :multiple="field.range ? field.range : false"
                      chips
                      small-chips
                      :label="field.label"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-combobox>
                  </template>
                  <v-date-picker
                    v-model="model[field.name]"
                    :range="field.range ? field.range : false"
                    :multiple="field.multiple ? field.multiple : false"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dateMenu = false">
                      Annuler
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dateMenu[0].save(model[field.name])"
                    >
                      Confirmer
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>

              <div>
                <v-menu
                  ref="timeMenu"
                  v-if="field.type == 'Time'"
                  v-model="timeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="model[field.name]"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="model[field.name]"
                      :label="field.label"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeMenu"
                    v-model="model[field.name]"
                    full-width
                    @click:minute="$refs.timeMenu[0].save(model[field.name])"
                  ></v-time-picker>
                </v-menu>
              </div>

              <div v-if="field.type == 'Timer'">
                <p>{{ field.label }}</p>
                <v-time-picker  v-model="model[field.name]" format="24hr"></v-time-picker>
              </div>

              <div v-if="field.type == 'Radio'">
                <p>{{ field.label }}</p>
                <v-radio-group row v-model="model[field.name]">
                  <v-radio
                    v-for="(item, i) of field.radio.data"
                    :key="i"
                    color="primary"
                    :label="item.name"
                    :value="item.value"
                  ></v-radio>
                </v-radio-group>
              </div>

              <div v-if="field.type == 'File'">
                      <v-file-input
                        v-model="model[field.name]"
                        :label="field.label"
                        :multiple="field.isFiles|| false"
                      ></v-file-input>
                    </div>
            </div>
          </v-col>

          <!-- <v-col v-if="categories && categories.length > 0" cols="12">
            <v-select
              :items="categoriesList"
              label="Categories"
              v-model="categoriesModel"
              :rules="[rules.required]"
              outlined
              chips
              multiple
            ></v-select>
          </v-col>
          <v-col cols="12" v-if="!withoutImages">
            <v-file-input
              color="main"
              show-size
              counter
              :multiple="multipleImage"
              :rules="imageIsRequired"
              v-model="images"
              :label="multipleImage ? 'Images' : 'Image'"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="5">
            <v-radio-group row v-model="lang">
              <v-radio color="main" :label="`Fr`" :value="'fr'"></v-radio>
              <v-radio color="red" :label="`En`" :value="'en'"></v-radio>
            </v-radio-group>
          </v-col>
          <v-row v-if="lang == 'fr'">
            <v-col cols="12">
              <v-text-field
                v-model="frTitle"
                color="main"
                label="Title"
                :rules="[rules.required]"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <quill-editor ref="editor" v-model="frBody" :options="editorOption" />
            </v-col>
          </v-row>
          <v-row v-if="lang == 'en'">
            <v-col cols="12">
              <v-text-field
                v-model="enTitle"
                color="main"
                :rules="[rules.required]"
                label="Title"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <quill-editor ref="editor" v-model="enBody" :options="editorOption" />
            </v-col>
          </v-row> -->

          <v-col cols="12">
            <v-btn
              :loading="loading"
              @click="submit()"
              dense
              class="white--text primary w-100 irgib"
              >{{ actionBtnLabel }}</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-card>
</template>

<script>
// import hljs from "highlight.js";
import { rules } from "../../utils/constant.js";
import { functions } from "../../utils/constant.js";
export default {
  props: {
    fields: {
      type: Array,
      default: () => [
        //   {
        //       name:"",
        //       type:"String | Boolean | Array | Select | Number",
        //       col:12,
        //       isRequiredForUpdate:false,
        //       isRequiredForAdd:true,
        //       existForUpdate:true,
        //       existForAdd:true,
        //       select:{
        //           foreign:{
        //               id:"",
        //           },
        //           data:["Annee"]
        //       }
        //   }
      ],
    },
    model: {
      default: () => {
        return {};
      },
    },
    collection: {
      type: String,
      default: "Cette collection",
    },
    title: {
      type: String,
      default: "Editeur",
    },
    actionBtnLabel: {
      type: String,
      default: "Soumettre",
    },
    // isRegister: {
    //   type: Boolean,
    //   default: false,
    // },
    // useKeywords: {
    //   type: Boolean,
    //   default: true,
    // },
    // imageIsRequired: {
    //   type: Boolean,
    //   default: false,
    // },
    withoutImages: {
      type: Boolean,
      default: true,
    },
    // categories: {
    //   type: Array,
    // },
    callback: {
      required: true,
    },
  },
  methods: {
    isValidField(field) {
      let success = true;
      if (this.id) {
        //   update
        if (!field.existForUpdate) {
          success = false;
        }
      } else {
        //
        if (!field.existForAdd) {
          success = false;
        }
      }

      if(field.type == "Static"){
          success = false;
      }

      return success;
    },
    getCondition(condition) {
      if (!condition) return true;

      if (condition.if) {
        if (condition.if.fieldName && condition.if.fieldName) {
          if (condition.if.operation == "=") {
            return this.model[condition.if.fieldName] == condition.if.fieldName;
          }
        }
      }

      return true;
    },
    getFieldRules(field) {
      let success = true;
      let fieldRules = [];
      if (this.id) {
        //   update
        if (!field.isRequiredForUpdate) {
          success = false;
        }
      } else {
        //
        if (!field.isRequiredForAdd) {
          success = false;
        }
      }
      if (success) {
        fieldRules.push(rules.required);
      }
      if (field.rules && Array.isArray(field.rules)) {
        fieldRules = [...fieldRules, ...field.rules];
      }

      return fieldRules;
    },
    getFieldOtherRules(field) {
      let success = true;
      let fieldRules = [];
      if (this.id) {
        //   update
        if (!field.isRequiredForUpdate) {
          success = false;
        }
      } else {
        //
        if (!field.isRequiredForAdd) {
          success = false;
        }
      }
      if (success) {
        fieldRules.push(rules.required);
      }
      if (field.select.otherRules && Array.isArray(field.select.otherRules)) {
        fieldRules = [...fieldRules, ...field.select.otherRules];
      }

      return fieldRules;
    },

    getSchema() {
      let schema;

      if (!this.withoutImages) {
        schema = new FormData();

        for (let field of this.fields) {
          if (field.isFiles) {
            for (let path of this.model[field.name]) {
              schema.append( field.name,path);
              // schema.append(path, this.model[field.name]);
            }
            console.log(this.model[field.name])
            console.log(schema)
          } else {
            if (field.type == "Static") {
              schema.append(field.name, field.value);
            } else {
              if (field.type == "Select") {
                if (
                  field.select &&
                  field.select.store &&
                  field.select.store.getter
                ) {
                  let fieldName = field.select.store.fieldName
                    ? field.select.store.fieldName
                    : "name";
                  let fieldValue = field.select.store.fieldValue
                    ? field.select.store.fieldValue
                    : "_id";

                  schema.append(
                    field.name,
                    functions.getItemId(
                      field.select.store.getter,
                      this.model[field.name],
                      fieldName,
                      fieldValue
                    )
                  );
                  continue;
                }
              }

              if (field.type == "Radio") {
                if (field.radio) {
                  let fieldName = field.radio.fieldName
                    ? field.radio.fieldName
                    : "name";
                  let fieldValue = field.radio.fieldValue
                    ? field.radio.fieldValue
                    : "_id";

                  schema.append(
                    field.name,
                    functions.getItemId(
                      field.radio.data,
                      this.model[field.name],
                      fieldName,
                      fieldValue
                    )
                  );
                  continue;
                }
              }

              if (field.type == "Color") {
                schema.append(
                  field.name,
                  this.model[field.name].hexa ?? this.model[field.name]
                );
                continue;
              }

              schema.append(field.name, this.model[field.name]);
            }
          }
        }
      } else {
        schema = this.getSchemaWithoutFormData();
      }

      return schema;
    },
    getSchemaWithoutFormData() {
      let schema = {};
      for (let field of this.fields) {
        if (field.type == "Static") {
          schema[field.name] = field.value;
        } else {
          if (field.type == "Select") {
            if (
              field.select &&
              field.select.store &&
              field.select.store.getter
            ) {
              let fieldName = field.select.store.fieldName
                ? field.select.store.fieldName
                : "name";
              let fieldValue = field.select.store.fieldValue
                ? field.select.store.fieldValue
                : "_id";

              schema[field.name] = functions.getItemId(
                field.select.store.getter,
                this.model[field.name],
                fieldName,
                fieldValue
              );
              continue;
            }

            if (
              field.select &&
              field.select.objectData &&
              Array.isArray(field.select.objectData)
            ) {
              if (
                field.select.allowOtherValue == true &&
                this.model[field.name] == "Autre"
              ) {
                schema[field.name] = this.model[field.name + "Other"];
              } else {
                let fieldName = field.select.fieldName
                  ? field.select.fieldName
                  : "name";
                let fieldValue = field.select.fieldValue
                  ? field.select.fieldValue
                  : "value";

                schema[field.name] = functions.getItemId(
                  field.select.objectData,
                  this.model[field.name],
                  fieldName,
                  fieldValue
                );
              }

              continue;
            }
          }

          if (field.type == "Radio") {
            if (field.radio) {
              let fieldName = field.radio.fieldName
                ? field.radio.fieldName
                : "name";
              let fieldValue = field.radio.fieldValue
                ? field.radio.fieldValue
                : "_id";

              schema[field.name] = functions.getItemId(
                field.radio.data,
                this.model[field.name],
                fieldName,
                fieldValue
              );

              continue;
            }
          }

          if (field.type == "Color") {
            schema[field.name] =
              this.model[field.name].hexa ?? this.model[field.name];
            continue;
          }

          schema[field.name] = this.model[field.name];
        }
      }

      return schema;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data = {};
        data.schema = this.getSchema();

        data.id = this.id ?? undefined;
        if (data.schema == undefined) {
          this.$store.dispatch("setSnackMessage", {
            message: "Something went wrong",
            snackbar: {
              bg: "red",
              color: "white",
              status: true,
            },
          });
        }
        try {
          // console.log(data);
          const response = await this.callback(data);
          this.$emit("success");
          console.log("response",response);
          this.$store.dispatch("setSnackMessage", {
            message:
              this.collection +
              ` a été ${this.id ? "modifié" : "ajouté"} avec success`,
            snackbar: {
              color: "red",
              status: true,
            },
          });
        } catch (error) {
          this.$store.dispatch("setSnackMessage", {
            message: error.response.data.message,
            snackbar: {
              bg: "red",
              color: "white",
              status: true,
            },
          });
          // console.log(error.response);
        }

        this.loading = false;
      }
    },
    remove(item) {
      this.keywords.splice(this.keywords.indexOf(item), 1);
      this.keywords = [...this.keywords];
    },

    seedObjectSelectData(item) {
      let unWantedData = item.select.unWantedData ?? [];
      if (this.model && this.model[item.name]) {
        unWantedData = unWantedData.map((t) => {
          if (t != this.model[item.name]) {
            return item.name;
          }
        });
      }

      item.select.data = functions.getNameList(
        item.select.objectData,
        item.select.fieldName,
        unWantedData
      );

      if (item.select.allowOtherValue) {
        if (
          this.model[item.name] &&
          this.model[item.name].length > 0 &&
          !item.select.data.includes(this.model[item.name])
        ) {
          this.model[item.name + "Other"] = this.model[item.name];
          this.model[item.name] = "Autre";
        }
        item.select.data.push("Autre");
      }

      console.log(item.select);
    },
  },
  watch: {
    model() {
      this.id = this.model._id ?? undefined;
    },
    fields() {
      for (let item of this.fields) {
        if (item.type == "Select") {
          if (item.select && item.select.store) {
            if (
              this.model[item.name] &&
              this.model[item.name][item.select.store.fieldName]
            ) {
              this.model[item.name] =
                this.model[item.name][item.select.store.fieldName];
            }

            item.select.data = functions.getNameList(
              item.select.store.getter,
              item.select.store.fieldName
            );
          } else if (
            item.select.objectData &&
            Array.isArray(item.select.objectData) &&
            item.select.objectData.length > 0
          ) {
            this.seedObjectSelectData(item);
          }
        }
      }
    },
  },
  async mounted() {
    // this.keywords = this.model.keywords ?? [];
    this.id = this.model._id ?? undefined;

    for (let item of this.fields) {
      // check bool condition

      if (item.boolShowingCondition && item.boolShowingCondition.trueText) {
        // init bool val
        // this.model[item.name + 'BoolCondition'] = item.boolShowingCondition.default ?? true
      }

      if (item.type == "Select") {
        if (item.select && item.select.store) {
          await item.select.store.action(item.select.store.params);
        } else if (
          item.select.objectData &&
          Array.isArray(item.select.objectData) &&
          item.select.objectData.length > 0
        ) {
          this.seedObjectSelectData(item);
        }
      }
    }
  },
  data() {
    return {
      id: "",
      rules,
      defaultKeySuggestion: [],
      categoryModel: "",
      categoriesList: [],
      categoriesModel: [],
      fieldList: [],
      keywords: [],
      images: [],
      dialog: false,
      loading: false,
      timeMenu: false,
      dateMenu: false,
    };
  },
};
</script>

<style></style>
