/**
 * Axios
 */
import axios from "axios";

/**
 * @auth
 */

export const checkUserAuth = ({}) => {

    return new Promise((resolve, reject) => {
        axios.get("/user/checkauth", ).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

/**
 * @schoolYear
 */

export const addSchoolYear = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/schoolyear", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateSchoolYear = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/schoolyear/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteSchoolYear = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/schoolyear/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};


/**
 * @semester
 */

export const addSemester = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/semester", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateSemester = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/semester/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteSemester = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/semester/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};


/**
 * @faculty
 */

export const addFaculty = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/faculty", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateFaculty = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/faculty/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteFaculty = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/faculty/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};


/**
 * @department
 */

export const addDepartment = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/department", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateDepartment = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/department/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteDepartment = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/department/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

/**
 * @option
 */

export const addOption = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/option", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateOption = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/option/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteOption = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/option/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};



/**
 * @standardFees
 */

export const addStandardFees = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/standardfees", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateStandardFees = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/standardfees/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteStandardFees = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/standardfees/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

/**
 * @additionalCoast
 */

export const addAdditionalCoast = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/additionalcoast", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateAdditionalCoast = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/additionalcoast/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteAdditionalCoast = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/additionalcoast/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};



/**
 * @Ue
 */

export const addUe = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/ue", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateUe = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/ue/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteUe = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/ue/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};


/**
 * @Ecue
 */

export const addEcue = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/ecue", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateEcue = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/ecue/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteEcue = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/ecue/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

/**
 * @grade
 */

export const addGrade = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/grade", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateGrade = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/grade/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteGrade = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/grade/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};


/**
 * @hourlyquota
 */

export const addHourlyQuota = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/hourlyquota", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateHourlyQuota = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/hourlyquota/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteHourlyQuota = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/hourlyquota/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

/**
 * @teacher
 */

export const addTeacher = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/teacher", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateTeacher = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/teacher/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteTeacher = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/teacher/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};



/**
 * @hall
 */

export const addHall = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/hall", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateHall = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/hall/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteHall = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/hall/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

/**
 * @user
 */

export const addUser = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/user", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateUser = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/user/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteUser = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/user/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};


/**
 * @godfather
 */

export const addGodfather = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/godfather", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateGodfather = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/godfather/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteGodfather = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/godfather/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};


/**
 * @scholarship
 */

export const addScholarship = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/scholarship", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateScholarship = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/scholarship/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteScholarship = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/scholarship/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};
export const printScholarship = ({ schema }) => {
    return new Promise((resolve, reject) => {
        axios.post("/scholarship/print", schema).then(
            (res) => {
                resolve(res.data.data);
            }).catch((error) => { reject(error) });
    });
};




/**
 * @hourlymass
 */

export const addHourlyMass = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/hourlymass", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateHourlyMass = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/hourlymass/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteHourlyMass = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/hourlymass/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};
/**
 * @mark
 */

export const addMark = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/mark", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateMark = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/mark/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteMark = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/mark/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};


/**
 * @student
 */

export const addStudent = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/student", schema).then(
            (res) => {
                resolve(res.data);
            },
            (error) => {
                console.log(error)
                reject(error);
            }
        );
    });
};
export const updateStudent = ({ schema, id }) => {

    return new Promise((resolve, reject) => {
        axios.put("/student/" + id, schema).then(
            (res) => {
                resolve(res.data);
            },
            (error) => {
                console.log(error)
                reject(error);
            }
        );
    });
};




/**
 * @timestable
 */

export const addTimesTable = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/timestable", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateTimesTable = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/timestable/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => {
            reject(error)
        });
    });
};

export const deleteTimesTable = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/timestable/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

/**
 * @additionaldebt
 */

export const addAdditionalDebt = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/additionaldebt", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateAdditionalDebt = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/additionaldebt/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteAdditionalDebt = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/additionaldebt/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

/**
 * @standarddebt
 */

export const addStandardDebt = ({ schema }) => {

    return new Promise((resolve, reject) => {
        axios.post("/standarddebt", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const updateStandardDebt = ({ schema, id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/standarddebt/" + id, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deleteStandardDebt = ({ id }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.delete("/standarddebt/" + id).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};


// profile management
export const updateUsers = ({ schema }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.put("/user/profile", schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};



// profile management
export const paidStandardDept = ({ schema }) => {
    // console.log(schema.email);

    const id = schema.student
    delete schema.student
    return new Promise((resolve, reject) => {
        axios.post(`/student/${id}/paid-standard-debt`, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};



export const paidAdditionalDept = ({ id, schema }) => {
    // console.log(schema.email);

    return new Promise((resolve, reject) => {
        axios.post(`/additionaldebt/${id}/paid-additional-debt`, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const manageRequest = ({ schema }) => {
    return new Promise((resolve, reject) => {
        axios.post(`/request/manage`, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};
export const cancelRequest = ({ id }) => {
    return new Promise((resolve, reject) => {
        axios.post(`/request/${id}/cancel`).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};
export const acceptRequest = ({ id }) => {
    return new Promise((resolve, reject) => {
        axios.post(`/request/${id}/accept`).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const deniedRequest = ({ id }) => {
    return new Promise((resolve, reject) => {
        axios.post(`/request/${id}/denied`).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};

export const emptyTheCatchRegister = () => {
    return new Promise((resolve, reject) => {
        axios.post(`/user/empty-the-catch-register`).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};
export const depositInTheBank = () => {
    return new Promise((resolve, reject) => {
        axios.post(`/user/deposit-in-bank-account`).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};


export const printOperationReceipt = ({ schema }) => {
    return new Promise((resolve, reject) => {
        axios.get("/operation/" + schema.id + "/print-receipt").then(
            (res) => {
                resolve(res.data.data);
            }).catch((error) => { reject(error) });
    });
};

export const printBadge = ({ schema }) => {
    return new Promise((resolve, reject) => {
        axios.post("/student/print-badge", schema).then(
            (res) => {
                resolve(res.data.data);
            }).catch((error) => { reject(error) });
    });
};
export const printAcademicTranscript = ({ schema }) => {
    return new Promise((resolve, reject) => {
        axios.post(`/student/print-academic-transcript?student=${schema.student}&semester=${schema.semester}&registrar=Engr. ADIGOUN Babatounde Gabriel`, schema).then(
            (res) => {
                resolve(res.data.link);
            }).catch((error) => { reject(error) });
    });
};
export const uplaodStudentAvatars = ({ schema }) => {
    return new Promise((resolve, reject) => {
        axios.post(`/student/upload-avatars`, schema).then(
            (res) => {
                resolve(res.data);
            }).catch((error) => { reject(error) });
    });
};